var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bundle-set-condition-item"},[_c('BundleSetConditionItemHeader',{attrs:{"index":_vm.index,"is-able-to-remove-condition":_vm.isAbleToRemoveCondition,"has-product-condition":_vm.hasProductCondition},on:{"onRemoveCondition":function (data) { return _vm.$emit('onRemoveCondition', data); },"onAddProductCondition":function (data) { return _vm.$emit('onAddProductCondition', data); }}}),_c('div',{staticClass:"form-group mt-3"},[_c('label',{staticClass:"mb-0"},[_vm._v("Product type")]),_c('div',{staticClass:"form-group-radio"},_vm._l((_vm.appleProductTypeGroups),function(ref){
var value = ref.value;
var label = ref.label;
return _c('div',{key:(_vm.index + "-type-" + value)},[_c('CInputRadio',{attrs:{"label":label,"value":value,"checked":_vm.vCondition.appleProductType.$model === value,"custom":"","disabled":_vm.handleIsCheckAble(value)},on:{"update:checked":function($event){return _vm.$emit('onChangeProductType', { index: _vm.index, value: value })}}})],1)}),0)]),_c('CInput',{attrs:{"is-valid":!_vm.vCondition.name.$error && null,"invalid-feedback":_vm.getErrorMessage(_vm.vCondition.name),"label":"Group name*","placeholder":"E.g. iPhone","data-test-id":"bundle-set-name"},model:{value:(_vm.vCondition.name.$model),callback:function ($$v) {_vm.$set(_vm.vCondition.name, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"vCondition.name.$model"}}),_c('CRow',{staticClass:"form-group"},[_c('CCol',[_c('label',{staticClass:"mb-0"},[_vm._v("Required")])]),_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group-switch"},[_c('CSwitch',{staticClass:"switch-custom",attrs:{"checked":_vm.vCondition.isRequired.$model,"variant":"3d","size":"sm","color":"success","data-test-id":"bundle-set-is-require"},on:{"update:checked":function($event){return _vm.$set(_vm.vCondition.isRequired, "$model", $event)}}}),_c('span',{staticClass:"typo-body-2"},[_vm._v(" "+_vm._s(_vm.vCondition.isRequired.$model ? 'Yes' : 'No')+" ")])],1)])],1),(_vm.hasProductCondition)?_c('ProductConditionList',{staticClass:"mb-2",attrs:{"default-data":{ conditions: _vm.vCondition.productConditions.$model },"no-condition-match":""}}):_c('BaseNoItemBanner',{staticClass:"mb-2",attrs:{"text":"There is no condition added."}}),_c('BundleSetConditionItemRule',{staticClass:"mb-2",attrs:{"v-condition":_vm.vCondition},on:{"onChange":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.$emit('onChangeRule', { index: _vm.index, value: value, key: key });
}}}),_c('BundleSetConditionItemDisplay',{attrs:{"v-condition":_vm.vCondition,"index":_vm.index},on:{"onChange":function (ref) {
	var key = ref.key;
	var value = ref.value;

	return _vm.$emit('onChangeDisplay', { index: _vm.index, value: value, key: key });
}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }