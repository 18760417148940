<template>
	<div class="bundle-set-condition-item">
		<BundleSetConditionItemHeader
			:index="index"
			:is-able-to-remove-condition="isAbleToRemoveCondition"
			:has-product-condition="hasProductCondition"
			@onRemoveCondition="(data) => $emit('onRemoveCondition', data)"
			@onAddProductCondition="(data) => $emit('onAddProductCondition', data)"
		/>

		<!-- Apple Product Type -->
		<div class="form-group mt-3">
			<label class="mb-0">Product type</label>
			<div class="form-group-radio">
				<div
					v-for="{value, label} in appleProductTypeGroups"
					:key="`${index}-type-${value}`"
				>
					<CInputRadio
						:label="label"
						:value="value"
						:checked="vCondition.appleProductType.$model === value"
						custom
						:disabled="handleIsCheckAble(value)"
						@update:checked="$emit('onChangeProductType', { index, value })"
					/>
				</div>
			</div>
		</div>
		<!-- End Apple Product Type -->

		<!-- Name -->
		<CInput
			v-model.trim="vCondition.name.$model"
			:is-valid="!vCondition.name.$error && null"
			:invalid-feedback="getErrorMessage(vCondition.name)"
			label="Group name*"
			placeholder="E.g. iPhone"
			data-test-id="bundle-set-name"
		/>
		<!-- End Name -->

		<!-- Required -->
		<CRow class="form-group">
			<CCol><label class="mb-0">Required</label></CCol>
			<CCol md="3">
				<div class="form-group-switch">
					<CSwitch
						:checked.sync="vCondition.isRequired.$model"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
						data-test-id="bundle-set-is-require"
					/>
					<span class="typo-body-2">
						{{ vCondition.isRequired.$model ? 'Yes' : 'No' }}
					</span>
				</div>
			</CCol>
		</CRow>
		<!-- End Required -->

		<!-- Product conditions -->
		<ProductConditionList
			v-if="hasProductCondition"
			:default-data="{ conditions: vCondition.productConditions.$model }"
			class="mb-2"
			no-condition-match
		/>
		<BaseNoItemBanner
			v-else
			text="There is no condition added."
			class="mb-2"
		/>
		<!-- End Product conditions -->

		<!-- Rule -->
		<BundleSetConditionItemRule
			:v-condition="vCondition"
			class="mb-2"
			@onChange="({ key, value }) => $emit('onChangeRule', { index, value, key })"
		/>
		<!-- End Rule -->

		<!-- Display -->
		<BundleSetConditionItemDisplay
			:v-condition="vCondition"
			:index="index"
			@onChange="({ key, value }) => $emit('onChangeDisplay', { index, value, key })"
		/>
		<!-- End Display -->
	</div>
</template>

<script>
import BundleSetConditionItemHeader from '@/components/BundleSetConditionItemHeader.vue';
import BundleSetConditionItemRule from '@/components/BundleSetConditionItemRule.vue';
import BundleSetConditionItemDisplay from '@/components/BundleSetConditionItemDisplay.vue';
import ProductConditionList from '@/components/ProductConditionList.vue';
import { getErrorMessage } from '../assets/js/validators';
import { APPLE_PRODUCT_TYPE_BUNDLE_SET_OPTIONS } from '../enums/bundleSet';
import { PRODUCT_TYPES } from '../enums/products';

export default {
	name: 'BundleSetConditionItem',

	components: {
		BundleSetConditionItemHeader,
		BundleSetConditionItemRule,
		BundleSetConditionItemDisplay,
		ProductConditionList,
	},

	props: {
		// Validation item
		vCondition: {
			type: Object,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
		isAbleToRemoveCondition: {
			type: Boolean,
			default: false,
		},
		indexAbleToCheckMainProductType: {
			type: Number,
			default: 0,
		},
		indexAbleToCheckPencilProductType: {
			type: Number,
			default: 0,
		},
	},

	computed: {
		hasProductCondition() {
			return this.vCondition.productConditions.$model.length > 0;
		},
		appleProductTypeGroups() {
			return APPLE_PRODUCT_TYPE_BUNDLE_SET_OPTIONS
				.filter((type) => type.value)
				.map((type) => ({ label: type.name, value: type.value }));
		},
	},

	methods: {
		getErrorMessage(validateItem) {
			const { key, data } = getErrorMessage(validateItem) ?? {};
			if (key) {
				return this.$t(key, data);
			}
			return null;
		},
		handleIsCheckAble(value) {
			switch (value) {
				case PRODUCT_TYPES.MAIN:
					if (this.indexAbleToCheckMainProductType >= 0) {
						return this.index !== this.indexAbleToCheckMainProductType;
					}

					return false;
				case PRODUCT_TYPES.PENCIL:
					if (this.indexAbleToCheckPencilProductType >= 0) {
						return this.index !== this.indexAbleToCheckPencilProductType;
					}

					return false;
				default:
					return false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-remove {
		@include typo-caption-2;

		line-height: rem(24);
		margin: 0 rem(24) 0 0;
		padding: 0 rem(16) 0 0;
		border-radius: 0;
		border-right: 1px solid $color-black-45;
	}

	.icon-trash {
		width: rem(12);
		margin: 0 rem(4) 0 0;
	}
</style>
