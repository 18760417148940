<template>
	<div>
		<CRow class="mt-5">
			<CCol lg="12">
				<h4 class="mb-4">
					<slot name="title">
					</slot>
				</h4>
				<slot name="description"></slot>
			</CCol>
		</CRow>

		<BannerInfo :desktop-img="desktopImg" :mobile-img="mobileImg">
			<slot name="detail">
				Banners will be displayed on the highlight banner widget as
				shown. Using the same size for desktop and mobile.
			</slot>
		</BannerInfo>

		<slot></slot>

		<CRow v-if="hasBannerLink">
			<CCol md="12">
				<CInput
					v-model.trim="$v.bannerLinkValue.$model"
					:is-valid="isValidBannerLink"
					:invalid-feedback="bannerLinkInvalidFormatText"
					:description="bannerLinkHelperText"
					label="Banner Link"
				/>
			</CCol>
		</CRow>

		<hr>
	</div>
</template>

<script>
import BannerInfo from '@/components/BannerInfo.vue';

import { requiredIf, url } from 'vuelidate/lib/validators';
import { pathOr } from '../assets/js/helpers';

export default {
	name: 'BannerContentWrapper',

	components: {
		BannerInfo,
	},

	validations() {
		return {
			bannerLinkValue: {
				// user must selected any banner image(s)
				// and the value on banner link input must not be empty
				required: requiredIf((data) => data.requiredLink && data.bannerLink),
				// check invalid url format
				url,
			},
		};
	},

	props: {
		desktopImg: {
			type: String,
			default: '',
		},
		mobileImg: {
			type: String,
			default: '',
		},
		requiredLink: {
			type: Boolean,
			default: false,
		},
		bannerLink: {
			type: String,
			default: '',
		},
		hasBannerLink: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		bannerLinkValue: {
			get() {
				return this.bannerLink;
			},
			set(value) {
				this.$emit('update:bannerLink', value);
			},
		},

		isValidBannerLink() {
			const isError = pathOr(false, ['$v', 'bannerLinkValue', '$error'])(this);
			return isError ? false : null;
		},

		bannerLinkInvalidFormatText() {
			return this.isValidBannerLink === false
				? this.$t('page.banner.create.linkBannerInvalidFormat')
				: null;
		},

		bannerLinkHelperText() {
			return this.isValidBannerLink === null
				? this.$t('page.banner.create.linkBannerHelperText')
				: null;
		},
	},
};
</script>
