<template>
	<div class="form-container">
		<!-- General info -->
		<h2 class="typo-h4 position-relative">
			{{ pageTitle }}
			<span class="title-caption typo-caption color-black-45">
				Bundle ID: <span class="typo-caption color-black-45">{{ formData.id || 'Generate after creates' }}</span>
			</span>
		</h2>
		<UploadLogo
			:logo.sync="$v.formData.bundleSetImage.$model"
			title="Bundle set image"
			sub-title="Bundle set image will be displayed on Cart page"
		/>
		<UploadLogo
			:logo.sync="$v.formData.bundlePrimarySetImage.$model"
			title="Bundle primary set image"
			sub-title="Bundle primary set image will be displayed on product detail page"
			recommend-resolution="128"
		/>
		<div class="mt-2">
			<CInput
				v-model.trim="$v.formData.nameEn.$model"
				:is-valid="!$v.formData.nameEn.$error && null"
				:invalid-feedback="nameEnError"
				:description="!$v.formData.nameEn.$error ? helperText : null"
				label="Bundle set name (EN)*"
				placeholder="e.g. Set gaming combo"
			/>
		</div>
		<div class="mt-2">
			<CInput
				v-model.trim="$v.formData.nameTh.$model"
				:is-valid="!$v.formData.nameTh.$error && null"
				:invalid-feedback="nameThError"
				:description="!$v.formData.nameTh.$error ? helperText : null"
				label="Bundle set name (TH)*"
				placeholder="e.g. เซ็ตเกมมิ่งคอมโบ"
			/>
		</div>
		<div class="mt-2">
			<label class="d-block">Bundle set status</label>
			<CInputRadioGroup
				:options="statusOptions"
				:checked.sync="formData.status"
				:custom="true"
				:inline="true"
				class="radio-group"
				name="status"
				data-test-id="bundle-set-status"
			/>
		</div>
		<div class="mt-5">
			<hr>
		</div>
		<!-- End General info -->

		<!-- Active -->
		<div class="form-group mt-5">
			<h2 class="typo-h4">Active dates</h2>
			<CRow>
				<CCol md="6" class="mt-2">
					<BaseInputDate
						v-model="$v.formData.startDate.$model"
						:disabled="!isEnableStartActiveDate"
						:is-valid="!$v.formData.startDate.$error"
						:min-date="minActiveDate"
						label="Start date*"
						invalid-feedback="Start date is required"
						placeholder="Select date"
						@input="handleActiveStartDateChange"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
				</CCol>
				<CCol md="6" class="mt-2">
					<BaseInputTime
						v-model="$v.formData.startTime.$model"
						:disabled="!isEnableStartActiveDate"
						:is-valid="!$v.formData.startTime.$error"
						:invalid-feedback="errorStartTime"
						label="Start time"
					/>
				</CCol>

				<CCol md="6" class="mt-3">
					<BaseInputDate
						v-model="$v.formData.endDate.$model"
						:disabled="!isEnableEndActiveDate || $v.formData.startDate.$invalid"
						:is-valid="!$v.formData.endDate.$error"
						:min-date="formData.startDate"
						label="End date*"
						invalid-feedback="End date is required"
						placeholder="Select date"
						data-test-id="end-date"
					>
						<template #append-content>
							<CIcon class="cil-calendar" name="cil-calendar" />
						</template>
					</BaseInputDate>
				</CCol>
				<CCol md="6" class="mt-3">
					<BaseInputTime
						v-model="$v.formData.endTime.$model"
						:disabled="!isEnableEndActiveDate"
						:is-valid="!$v.formData.endTime.$error"
						:invalid-feedback="errorEndTime"
						label="End time"
					/>
				</CCol>
			</CRow>
			<div class="mt-5">
				<hr>
			</div>
		</div>
		<!-- End Active -->

		<!-- Customer group -->
		<div class="form-group mt-5">
			<BundleSetCustomerGroup
				:customer-groups.sync="formData.customerGroups"
			/>
		</div>
		<div class="mt-5">
			<hr>
		</div>
		<!-- End Customer group -->

		<!-- Bundle description -->
		<div class="form-group mt-2">
			<BannerContentWrapper
				ref="content"
				:required-link="!!formData.desktopBanner && !!formData.mobileBanner"
				:banner-link="formData.bannerLink"
				desktop-img="/assets/images/bundle-banner.svg"
				@update:bannerLink="formData.bannerLink = $event"
			>
				<template #title>
					Bundle detail
				</template>
				<template #detail>
					This location is a bundle detail page of product that customer use to customize and buy their bundle products.
				</template>
				<BaseMediaSelectorBox
					ref="desktopBanner"
					v-model="formData.desktopBanner"
					title="Desktop bundle banner"
					no-image-text="No banner"
					show-helper
					width="2352"
					height="640"
					class="mt-4"
					required
				/>
				<BaseMediaSelectorBox
					ref="mobileBanner"
					v-model="formData.mobileBanner"
					title="Mobile bundle banner"
					no-image-text="No banner"
					show-helper
					width="656"
					height="240"
					class="mt-2"
					required
				/>
			</BannerContentWrapper>
			<CTextarea
				v-model="$v.formData.descriptionEn.$model"
				:is-valid="!$v.formData.descriptionEn.$error && null"
				:invalid-feedback="descriptionEnError"
				:description="!$v.formData.descriptionEn.$error ? helperTextDescription : null"
				label="Description (EN)*"
				placeholder="e.g. 5% discount with minimum spend of ฿6,900"
				rows="3"
				@change="$v.formData.descriptionEn.$touch()"
			/>
			<CTextarea
				v-model="$v.formData.descriptionTh.$model"
				:is-valid="!$v.formData.descriptionTh.$error && null"
				:invalid-feedback="descriptionThError"
				:description="!$v.formData.descriptionTh.$error ? helperTextDescription : null"
				label="Description (TH)*"
				placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
				rows="3"
				@change="$v.formData.descriptionTh.$touch()"
			/>
			<CInput
				v-model.trim="$v.formData.infoNameEn.$model"
				:is-valid="!$v.formData.infoNameEn.$error && null"
				:invalid-feedback="infoNameEnError"
				:description="!$v.formData.infoNameEn.$error ? helperText : null"
				label="Info name (EN)*"
				placeholder="e.g. Set gaming combo"
			/>
			<CInput
				v-model.trim="$v.formData.infoNameTh.$model"
				:is-valid="!$v.formData.infoNameTh.$error && null"
				:invalid-feedback="infoNameThError"
				:description="!$v.formData.infoNameTh.$error ? helperText : null"
				label="Info name (TH)*"
				placeholder="e.g. เซ็ตเกมมิ่งคอมโบ"
			/>
			<div>
				<label>Information promotion Top description (EN)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.infoDescriptionEn.$model"
					:is-valid="!$v.formData.infoDescriptionEn.$error"
					:invalid-feedback="infoDescriptionEnError"
					:description="!$v.formData.infoDescriptionEn.$error ? helperTextDescription : null"
					placeholder="e.g. 5% discount with minimum spend of ฿6,900"
				/>
			</div>
			<div>
				<label>Information promotion Top description (TH)*</label>
				<BaseRichTextEditor
					v-model="$v.formData.infoDescriptionTh.$model"
					:is-valid="!$v.formData.infoDescriptionTh.$error"
					:invalid-feedback="infoDescriptionThError"
					:description="!$v.formData.infoDescriptionTh.$error ? helperTextDescription : null"
					placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
				/>
				<div class="mt-4">
					<hr>
				</div>
			</div>
			<div>
				<label>Information promotion Bottom description (EN)</label>
				<BaseRichTextEditor
					v-model="formData.infoDescriptionBottomEn"
					placeholder="e.g. 5% discount with minimum spend of ฿6,900"
				/>
			</div>
			<div>
				<label>Information promotion Bottom description (TH)</label>
				<BaseRichTextEditor
					v-model="formData.infoDescriptionBottomTh"
					placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
				/>
				<div class="mt-4">
					<hr>
				</div>
			</div>
			<div>
				<h4 class="mt-5 mb-4">
					Reward bundle set detail
				</h4>
				<BannerInfo>
					This location is a detail page of product that customer use to inspect and buy the product
				</BannerInfo>

				<label>Reward description (EN)</label>
				<BaseRichTextEditor
					v-model="formData.rewardDescriptionEn"
					placeholder="e.g. 5% discount with minimum spend of ฿6,900"
				/>
			</div>
			<div>
				<label>Reward description (TH)</label>
				<BaseRichTextEditor
					v-model="formData.rewardDescriptionTh"
					placeholder="e.g. ส่วนลด 5% เมื่อซื้อครบ 6,900 บาท"
				/>
			</div>
		</div>
		<!-- Bundle description -->
	</div>
</template>

<script>
import dayjs from 'dayjs';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import BundleSetCustomerGroup from '@/components/BundleSetCustomerGroup.vue';
import UploadLogo from '@/components/UploadLogo.vue';
import BannerInfo from '@/components/BannerInfo.vue';

import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { afterDateTime, timeFormat } from '../assets/js/validators';
import {
	BUNDLE_SET_STATUSES,
	BUNDLE_SET_PERIODS_STATUSES,
	BUNDLE_SET_LABEL_TITLE_TABS,
	BUNDLE_SET_STATUS_OPTIONS,
} from '../enums/bundleSet';

export default {
	name: 'BundleSetStepGeneralInfo',

	components: {
		BannerContentWrapper,
		BaseMediaSelectorBox,
		BundleSetCustomerGroup,
		UploadLogo,
		BannerInfo,
	},

	validations: {
		formData: {
			bundleSetImage: {},
			bundlePrimarySetImage: {},
			nameEn: {
				required,
				maxLength: maxLength(255),
			},
			nameTh: {
				required,
				maxLength: maxLength(255),
			},
			descriptionEn: {
				required,
			},
			descriptionTh: {
				required,
			},
			startDate: {
				required,
			},
			endDate: {
				required,
			},
			startTime: {
				required,
				timeFormat,
			},
			endTime: {
				required,
				timeFormat,
				after: afterDateTime({
					fromDateKey: 'startDate',
					fromTimeKey: 'startTime',
					toDateKey: 'endDate',
					toTimeKey: 'endTime',
				}),
			},
			infoNameEn: {
				required,
				maxLength: maxLength(255),
			},
			infoNameTh: {
				required,
				maxLength: maxLength(255),
			},
			infoDescriptionEn: {
				required,
			},
			infoDescriptionTh: {
				required,
			},
			customerGroups: {
				required,
			},
		},
	},

	mixins: [validationMixin],

	props: {
		defaultData: {
			type: Object,
			default: null,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const {
			id,
			bundleSetImage = null,
			bundlePrimarySetImage = null,
			nameEn = null,
			nameTh = null,
			status = BUNDLE_SET_STATUSES.INACTIVE,
			periodValue,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',
			customerGroups = [],
			desktopBanner = null,
			mobileBanner = null,
			bannerLink = null,
			descriptionEn = null,
			descriptionTh = null,
			infoNameEn = null,
			infoNameTh = null,
			infoDescriptionEn = null,
			infoDescriptionTh = null,
			infoDescriptionBottomEn = null,
			infoDescriptionBottomTh = null,
			rewardDescriptionEn = null,
			rewardDescriptionTh = null,
		} = this.defaultData || {};

		return {
			statusOptions: BUNDLE_SET_STATUS_OPTIONS,
			minActiveDate: new Date(),
			periodValue,
			formData: {
				id,
				bundleSetImage,
				bundlePrimarySetImage,
				nameEn,
				nameTh,
				status,
				startDate,
				startTime,
				endDate,
				endTime,
				customerGroups,
				desktopBanner,
				mobileBanner,
				bannerLink,
				descriptionEn,
				descriptionTh,
				infoNameEn,
				infoNameTh,
				infoDescriptionEn,
				infoDescriptionTh,
				infoDescriptionBottomEn,
				infoDescriptionBottomTh,
				rewardDescriptionEn,
				rewardDescriptionTh,
			},
			pageTitle: BUNDLE_SET_LABEL_TITLE_TABS.GENERAL_INFO,
		};
	},

	computed: {
		errorStartTime() {
			if (!this.$v.formData.startTime.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.startTime.timeFormat) {
				return this.$t('global.error.timeInvalidFormat');
			}

			return null;
		},
		errorEndTime() {
			if (!this.$v.formData.endTime.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.endTime.timeFormat) {
				return this.$t('global.error.timeInvalidFormat');
			}

			if (!this.$v.formData.endTime.after) {
				return this.$t('global.error.timeAfter', { time: this.formData.startTime });
			}

			return null;
		},
		isEnableStartActiveDate() {
			return ![BUNDLE_SET_PERIODS_STATUSES.ON_GOING, BUNDLE_SET_PERIODS_STATUSES.EXPIRED].includes(this.periodValue);
		},
		isEnableEndActiveDate() {
			return ![BUNDLE_SET_PERIODS_STATUSES.EXPIRED].includes(this.periodValue);
		},
		nameEnError() {
			if (!this.$v.formData.nameEn.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.nameEn.maxLength) {
				return this.$t('global.error.maximumCharacters');
			}

			return null;
		},
		nameThError() {
			if (!this.$v.formData.nameTh.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.nameTh.maxLength) {
				return this.$t('global.error.maximumCharacters');
			}

			return null;
		},
		infoNameEnError() {
			if (!this.$v.formData.infoNameEn.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.infoNameEn.maxLength) {
				return this.$t('global.error.maximumCharacters');
			}

			return null;
		},
		infoNameThError() {
			if (!this.$v.formData.infoNameTh.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.infoNameTh.maxLength) {
				return this.$t('global.error.maximumCharacters');
			}

			return null;
		},
		helperText() {
			return this.$t('global.error.recommendCharacters', { number: 60 });
		},
		descriptionEnError() {
			if (!this.$v.formData.descriptionEn.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		descriptionThError() {
			if (!this.$v.formData.descriptionTh.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		infoDescriptionEnError() {
			if (!this.$v.formData.infoDescriptionEn.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		infoDescriptionThError() {
			if (!this.$v.formData.infoDescriptionTh.required) {
				return this.$t('global.error.required');
			}

			return 'null';
		},
		helperTextDescription() {
			return this.$t('global.error.recommendCharacters', { number: 140 });
		},
	},

	watch: {
		formData: {
			deep: true,
			handler(formData) {
				this.$emit('onChanged', {
					valid: !this.$v.$invalid,
					formData,
				});
			},
		},
	},

	methods: {
		handleActiveStartDateChange(value) {
			if (!this.formData.endDate || dayjs(value).isAfter(dayjs(this.formData.endDate))) {
				this.formData.endDate = value;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-container {
		margin-top: rem(80);

		.title-caption {
			position: absolute;
			right: 0;
			bottom: rem(4);
			font-weight: $font-weight-semi-bold;

			span {
				font-weight: $font-weight-bold;
			}
		}
	}

	.mapping-sku-box {
		border-radius: rem(4);
		border: solid rem(1) $color-gray-400;
	}
</style>
